import React       from 'react';
import { graphql } from 'gatsby';
import Breakpoint  from 'react-socks';
import Img         from 'gatsby-image';

import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Boxgroup          from '@interness/theme-twentynineteen/src/components/Boxgroup/Boxgroup';
import Display           from '@interness/theme-twentynineteen/src/components/Display/Display';
import CTASection        from '@interness/web-core/src/components/modules/CTASection/CTASection';
import ServicesDisplay   from '@interness/theme-twentynineteen/src/components/ServicesDisplay/ServicesDisplay';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import Mounted           from '@interness/web-core/src/components/structure/Mounted/Mounted';
import Carousel          from '@interness/web-core/src/components/media/Carousel/Carousel';

const IndexPage = (props) => {
  const services = [
    {
      display_name: 'Werkstatt',
      image: props.data.werkstattImage.childImageSharp.fluid,
      description: 'Hier erfahren Sie mehr über unsere leistungsfähigen Meisterwerkstatt',
      linkTo: '/service'
    },
    {
      display_name: 'Altgoldankauf',
      image: props.data.altgoldImage.childImageSharp.fluid,
      description: 'Wir kaufen Altgold aus vielen, unterschiedlichen Bereichen an...',
      linkTo: '/service'
    }
  ];
  return (
    <>
      <Carousel>
        {props.data.headerImages.images.map((image) => (
          <Img fluid={image.file.localFile.childImageSharp.fluid}
               alt={image.file.localFile.name}/>
        ))}
      </Carousel>
      <Mounted>
        <Breakpoint large up>
          <Boxgroup/>
          <Spacer/>
        </Breakpoint>
      </Mounted>
      <Wrapper>
        <section>
          <Heading subtitle={'Herzlich Willkommen'}>Juwelier Dinger</Heading>
          <Spacer height={20}/>
          <span style={{ textAlign: 'justify' }}>
            <p>Willkommen bei Juwelier Dinger, Ihrem vertrauenswürdigen Juweliergeschäft im Herzen von Bad Harzburg. Mit unserer langjährigen Erfahrung und dem Fachwissen als Goldschmiedemeisters bieten wir Ihnen eine exquisite Auswahl an Uhren, Schmuck, Trauringen, Eheringen und Antragsringen. In unserer Meisterwerkstatt kümmern wir uns mit Hingabe und Präzision um jedes Detail.</p>
            <p>Unser Sortiment umfasst eine vielfältige Auswahl an hochwertigen Uhren und Schmuckstücken, die für jede Gelegenheit und jeden Stil passend sind. Besonders stolz sind wir auf unsere umfangreiche Kollektion an Trauringen und Eheringen, die Ihre besonderen Momente unvergesslich machen. Darüber hinaus bieten wir individuelle Antragsringe an, die Ihre einzigartigen Liebesgeschichten perfekt ergänzen.</p>
            <p>Neben unserem beeindruckenden Sortiment legen wir großen Wert auf erstklassigen Kundenservice. Unsere erfahrenen Mitarbeiter stehen Ihnen mit fachkundiger Beratung zur Seite und helfen Ihnen, das perfekte Schmuckstück oder die ideale Uhr zu finden. Wir bieten auch einen umfassenden Reparaturservice an, um die Langlebigkeit und Schönheit Ihrer Kostbarkeiten zu gewährleisten.</p>
            <p>Bei Juwelier Dinger verstehen wir auch die emotionale Bedeutung von Erbstücken und bieten daher einen Altgoldankauf an, mit dem Sie neuen Schmuck kreieren oder vorhandene Stücke aufwerten können.</p>
            <p>Besuchen Sie uns in Bad Harzburg und erleben Sie die Welt der feinen Uhren und des exquisiten Schmucks bei Juwelier Dinger. Wir freuen uns darauf, Sie in unserem Geschäft zu begrüßen und Ihnen bei der Auswahl Ihres nächsten Schmuckstücks oder Ihrer Traumuhr behilflich zu sein.</p>
            <p>Bis bald, Ihr Thomas Dinger und Team</p>
          </span>
        </section>
        <LiveAnnouncements/>
        <Spacer height={20}/>
        <section>
          <Heading tag={'h2'}>Unser Angebot</Heading>
          <Spacer height={20}/>
          <Display/>
        </section>
        <Spacer height={20}/>
        <section>
          <Heading tag={'h2'}>Werkstatt und Service</Heading>
          <Spacer height={20}/>
          <ServicesDisplay services={services}/>
        </section>
      </Wrapper>
      <CTASection/>
    </>
  )
};

export default IndexPage;

export const query = graphql`
    query {
        headerImages: directusMediaCollection(name: {eq: "home"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1980, maxHeight: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        werkstattImage: file(relativePath: {eq: "index/werkstatt.jpg"}) {
            name
            childImageSharp {
                fluid(maxWidth: 700, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        altgoldImage: file(relativePath: {eq: "index/altgold.jpg"}) {
            name
            childImageSharp {
                fluid(maxWidth: 700, maxHeight: 247, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;